import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import useIdleSignOut from 'common/hooks/useIdleSignOut';

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const { username, userId } = useSelector(
    ({ auth }) => ({ username: auth.username, userId: auth.userId }),
    shallowEqual
  );

  useIdleSignOut();

  if (!username || !userId) {
    return (
      <Navigate to='/signin' state={{ from: `${location.pathname}${location.search}` }} replace />
    );
  }

  return <div key={username}>{children}</div>;
};

ProtectedRoutes.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoutes;
