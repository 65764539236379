/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import {
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useEffect, useState } from 'react';
import { filter, find, includes, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { lighten, useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { loadRequestDetails } from 'store/thunks/orderStatusThunks';
import LoadingModule from 'common/components/LoadingModule';
import gender from 'common/constants/gender';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';

import EScriptOrdersProgressTab from './EScriptOrdersProgressTab';

const RequestDetails = {
  Patient: 'Patient',
  Prescription: 'Prescription',
  Pharmacy: 'Pharmacy',
  Prescriber: 'Prescriber',
};

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const EScriptRequestView = ({ referenceId, open, onClose }) => {
  const dispatch = useDispatch();
  const { pages, currentPage } = useSelector(({ orderStatus: { eScriptOrders } }) => eScriptOrders);
  const [viewMoreElements, setViewMoreElements] = useState([]);
  const requests = pages[currentPage] || [];
  const requestDetails = find(
    requests,
    (request) => request?.referenceId === referenceId
  )?.requestDetails;

  const patient = requestDetails?.patient;
  const prescription = requestDetails?.prescription;
  const toPharmacy = requestDetails?.prescription?.toPharmacy;
  const prescribedBy = requestDetails?.prescription?.prescribedBy;
  const practice = requestDetails?.practice;
  const events = requestDetails?.events || [];

  const isOpenPatients = includes(viewMoreElements, RequestDetails.Patient);
  const isOpenPrescriptions = includes(viewMoreElements, RequestDetails.Prescription);
  const isOpenPharmacy = includes(viewMoreElements, RequestDetails.Pharmacy);
  const isOpenPrescriber = includes(viewMoreElements, RequestDetails.Prescriber);

  const upsertViewMoreElements = (element) => {
    if (includes(viewMoreElements, element)) {
      const updatedElements = filter(
        viewMoreElements,
        (currentElement) => element !== currentElement
      );

      return setViewMoreElements([...updatedElements]);
    }

    viewMoreElements.push(element);
    return setViewMoreElements([...viewMoreElements]);
  };

  const eventData = find(
    events,
    ({ actionItemType }) => actionItemType === 'ORDER_CREATED_IN_DYNAMO'
  )?.interactionData;

  const shippingAddress = eventData?.originalEventPayload?.fulfillmentDetails?.shippingAddress;

  useEffect(() => {
    if (open) {
      dispatch(loadRequestDetails({ referenceId }));
    }
  }, [referenceId, dispatch, open]);

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <Grid
        container
        sx={{ px: 3, py: 1, bgcolor: 'primary.light' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography component='h2' variant='h6' fontWeight='bold'>
          Request - {referenceId}
        </Typography>

        <IconButton onClick={onClose} aria-label='Close Dialog'>
          <CloseIcon />
        </IconButton>
      </Grid>

      <DialogContent>
        {isNil(requestDetails) ? (
          <LoadingModule height='25vh' />
        ) : (
          <Grid container direction='row' sx={{ px: 4 }}>
            <Grid item md={6}>
              <Grid sx={{ pb: 2 }}>
                <Grid container direction='row' alignItems='center'>
                  <Typography variant='h6' fontWeight='bold'>
                    Patient
                  </Typography>

                  <IconButton
                    aria-label='Toggle Details patient'
                    onClick={() => upsertViewMoreElements(RequestDetails.Patient)}
                  >
                    {isOpenPatients ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>

                <ItemLabelValue label='MPI' value={patient?.mpi} />
                <ItemLabelValue
                  label='Name'
                  value={`${patient?.lastName}, ${patient?.firstName}`}
                />
                <ItemLabelValue label='External Id' value={patient?.externalPatientId} />
                <Collapse in={isOpenPatients} timeout='auto' unmountOnExit>
                  <ItemLabelValue label='DOB' value={patient?.dob} />
                  <ItemLabelValue label='Gender' value={gender[patient?.gender]} />
                  <ItemLabelValue label='Email' value={patient?.email} />
                  <ItemLabelValue label='Phone' value={patient?.mobilePhone} />
                </Collapse>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Grid container direction='row' alignItems='center'>
                  <Typography variant='h6' fontWeight='bold'>
                    Prescription
                  </Typography>

                  <IconButton
                    aria-label='Toggle Details prescription'
                    onClick={() => upsertViewMoreElements(RequestDetails.Prescription)}
                  >
                    {isOpenPrescriptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Grid>

                <ItemLabelValue label='Description' value={prescription?.description} />
                <ItemLabelValue label='Strength' value={prescription?.strength} />
                <Collapse in={isOpenPrescriptions} timeout='auto' unmountOnExit>
                  <ItemLabelValue label='Expiration Date' value={prescription?.expirationDate} />
                  <ItemLabelValue label='DAW' value={prescription?.dispenseAsWritten} />
                  <ItemLabelValue label='Directions' value={prescription?.directions} />
                  <ItemLabelValue label='Quantity' value={prescription?.quantity} />
                  <ItemLabelValue label='Refills' value={prescription?.totalRefills} />
                  <ItemLabelValue label='Needs By Date' value={prescription?.needsByDate} />
                  <ItemLabelValue label='Notes' value={prescription?.notes} />
                </Collapse>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Typography variant='h6' fontWeight='bold'>
                  Practice
                </Typography>

                <Grid sx={{ pt: 1 }}>
                  <ItemLabelValue label='Practice Id' value={practice?.practiceId} />
                  <ItemLabelValue label='Name' value={practice?.practiceName} />
                </Grid>
              </Grid>

              <Grid sx={{ pb: 2, pt: 2 }}>
                <Typography variant='h6' fontWeight='bold'>
                  Fulfillment - Shipping Address
                </Typography>

                <Grid sx={{ pt: 1 }}>
                  <ItemLabelValue label='Line 1' value={shippingAddress?.addressLine1} />
                  <ItemLabelValue label='Line 2' value={shippingAddress?.addressLine2} />
                  <ItemLabelValue label='City' value={shippingAddress?.addressCity} />
                  <ItemLabelValue
                    label='State'
                    value={
                      find(usStateAbbreviation, { abbreviation: shippingAddress?.addressState })
                        ?.name || shippingAddress?.addressState
                    }
                  />
                  <ItemLabelValue label='Zip' value={shippingAddress?.addressZip} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <Typography variant='h6' fontWeight='bold'>
                Transfer Details
              </Typography>

              <Grid container direction='row' alignItems='center' sx={{ pb: 1 }}>
                <Typography variant='h6' fontWeight='bold'>
                  To Pharmacy
                </Typography>

                <IconButton
                  aria-label='Toggle Details pharmacy'
                  onClick={() => upsertViewMoreElements(RequestDetails.Pharmacy)}
                >
                  {isOpenPharmacy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>

              <ItemLabelValue label='Name' value={toPharmacy?.name} />
              <ItemLabelValue label='Pharmacist' value={toPharmacy?.pharmacistName} />
              <Collapse in={isOpenPharmacy} timeout='auto' unmountOnExit>
                <ItemLabelValue label='DEA' value={toPharmacy?.dea} />
                <ItemLabelValue label='NPI' value={toPharmacy?.npi} />
                <ItemLabelValue label='Phone' value={toPharmacy?.phoneNumber} />
                <ItemLabelValue label='Line 1' value={toPharmacy?.pharmacyAddress?.addressLine1} />
                <ItemLabelValue label='Line 2' value={toPharmacy?.pharmacyAddress?.addressLine2} />
                <ItemLabelValue label='City' value={toPharmacy?.pharmacyAddress?.addressCity} />
                <ItemLabelValue label='State' value={toPharmacy?.pharmacyAddress?.addressState} />
                <ItemLabelValue label='Zip' value={toPharmacy?.pharmacyAddress?.addressZip} />
              </Collapse>

              <Grid container direction='row' alignItems='center'>
                <Typography variant='h6' fontWeight='bold' sx={{ pb: 1, pt: 3 }}>
                  Prescribed By
                </Typography>

                <IconButton
                  sx={{ mt: 2 }}
                  aria-label='Toggle Details prescriber'
                  onClick={() => upsertViewMoreElements(RequestDetails.Prescriber)}
                >
                  {isOpenPrescriber ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>

              <ItemLabelValue label='Title' value={prescribedBy?.title} />
              <ItemLabelValue label='First Name' value={prescribedBy?.firstName} />
              <ItemLabelValue label='Middle Name' value={prescribedBy?.middleName} />
              <ItemLabelValue label='Last Name' value={prescribedBy?.lastName} />
              <Collapse in={isOpenPrescriber} timeout='auto' unmountOnExit>
                <ItemLabelValue label='DEA' value={prescribedBy?.dea} />
                <ItemLabelValue label='NPI' value={prescribedBy?.npi} />
                <ItemLabelValue label='Phone' value={prescribedBy?.phoneNumber} />
                <ItemLabelValue label='Line 1' value={prescribedBy?.address?.addressLine1} />
                <ItemLabelValue label='Line 2' value={prescribedBy?.address?.addressLine2} />
                <ItemLabelValue label='City' value={prescribedBy?.address?.addressCity} />
                <ItemLabelValue label='State' value={prescribedBy?.address?.addressState} />
                <ItemLabelValue label='Zip' value={prescribedBy?.address?.addressZip} />
              </Collapse>
            </Grid>

            <EScriptOrdersProgressTab initialTab='events' referenceId={referenceId} />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

EScriptRequestView.propTypes = {
  referenceId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EScriptRequestView;

const ItemLabelValue = ({ value, label }) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ pb: 1 }}>
      <Grid item sx={{ minWidth: 250 }}>
        <Typography variant='body1' sx={{ color: lighten(theme.palette.text.primary) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item sx={{ minWidth: 100 }}>
        <Typography variant='body1'>{value || 'NA'}</Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

ItemLabelValue.defaultProps = { label: '', value: 'NA' };
