import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    resetPartnerState(state) {
      Object.assign(state, initialState);
    },
    loadPartners(state, action) {
      const { partners, currentTenantId } = action.payload;

      state[currentTenantId] = partners;
    },
  },
});

export const partnerActions = partnerSlice.actions;
export default partnerSlice.reducer;
