/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listApiOrdersStatusGql = gql`
  query listApiOrdersStatus(
    $limit: Int = 25
    $offset: Int = 0
    $filter: ListApiOrdersFilter
    $sortBy: ListApiOrdersSortBy = { sortKey: createdAt, sortOrder: DESC }
  ) {
    listApiOrdersStatus(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        orderType
        referenceId
        pomOrderId
        patient {
          mpi
          firstName
          lastName
        }
        partner {
          partnerName
          partnerUuid
        }
        externalPatientId
        createdAt
        status
      }
    }
  }
`;

export const getRequestByReferenceIdGql = gql`
  query getPomRequestByReferenceId($referenceId: String!) {
    getPomRequestByReferenceId(referenceId: $referenceId) {
      orderType
      referenceId
      pomOrderId
      externalPatientId
      status
      createdAt
      prescription {
        description
        strength
        expirationDate
        dispenseAsWritten
        directions
        quantity
        totalRefills
        needsByDate
        notes
        toPharmacy {
          pharmacyAddress {
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZip
          }
          phoneNumber
          dea
          name
          npi
          pharmacistName
        }
        prescribedBy {
          npi
          firstName
          lastName
          middleName
          phoneNumber
          address {
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZip
          }
          dea
          deaState
          emailAddress
          title
        }
      }
      patient {
        mpi
        firstName
        lastName
        email
        mobilePhone
        gender
        dob
      }
      partner {
        partnerName
      }
      practice {
        practiceId
        practiceName
        prescriberName
        phone
        line1
        line2
        city
        state
        zip
      }
      events
      errors
    }
  }
`;
