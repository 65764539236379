import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterBulkLabels } from 'store/thunks/workQueueThunks';
import lineItemEnum from 'enums/lineItem.enum';

const BulkLabelsFilterButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const popoverId = anchorEl ? 'WorkQueue-BulkLabelsFilterButton-Popover' : undefined;

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const filter = useSelector(({ workQueue: { bulkLabels } }) => ({
    ...bulkLabels.filter,
    lastPrintedDate:
      bulkLabels.filter?.lastPrintedDate && new Date(bulkLabels.filter.lastPrintedDate),
    needsByDate: bulkLabels.filter?.needsByDate && new Date(bulkLabels.filter.needsByDate),
  }));

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterBulkLabels({
        filter: {
          ...formData,
          lastPrintedDate: formData.lastPrintedDate && formData.lastPrintedDate.toISOString(),
          needsByDate: formData.needsByDate && formData.needsByDate.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name='patientName'
                    id='WorkQueue-BulkLabelsFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Medication Name'
                    name='medicationName'
                    id='WorkQueue-BulkLabelsFilterButton-medicationName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Needs By Date'
                    name='needsByDate'
                    id='WorkQueue-BulkLabelsFilterButton-needsByDate-dateField'
                  />

                  <DateField
                    label='Last Printed Date'
                    name='lastPrintedDate'
                    id='WorkQueue-BulkLabelsFilterButton-lastPrintedDate-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Printed'
                    name='isPrinted'
                    id='WorkQueue-BulkLabelsFilterButton-isPrinted-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </SelectField>

                  <SelectField
                    label='Product Status'
                    name='lineItemStatus'
                    id='WorkQueue-BulkLabelsFilterButton-status-dropdown'
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    <MenuItem value={lineItemEnum.PRODUCT_FULFILLMENT}>Needs Filled</MenuItem>
                    <MenuItem value={lineItemEnum.PRODUCT_FULFILLED}>Filled</MenuItem>
                  </SelectField>

                  <SelectField
                    label='Tagged For Review'
                    name='taggedForReview'
                    id='WorkQueue-BulkLabelsFilterButton-taggedForReview-dropdown'
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    <MenuItem value>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default BulkLabelsFilterButton;
