/* eslint-disable max-lines */
import moment from 'moment';
import PropTypes from 'prop-types';
import { capitalize, find, isEmpty, omit, pick } from 'lodash';
import { lighten, useTheme } from '@mui/material/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

import { useContext, useState } from 'react';

import smartscriptsPharmacyAddress from 'enums/partner.enum';
import ResolveDrugProfileModal from 'modules/WorkQueue/components/ResolveDrugProfileModal';
import useInfoDialog from 'common/hooks/useInfoDialog';
import momentTimeZone from 'moment-timezone';

import { PrescriptionReviewStatus } from 'enums/prescription.enum';
import EditOrderDetailsModal from 'modules/WorkQueue/components/PrescriptionResolveModal';
import ResolveDirectionModal from 'modules/WorkQueue/components/ResolvePrescriptionModal';
import { PrescriptionReviewPayloadContext } from 'common/hooks/usePrescriptionReviewPayloadContext';
import { overridePrescription } from 'store/thunks/workQueueThunks';
import { ItemLabelValue, OverrideItemLabelValue } from './PatientPrescriptionCustomLabels';

const PatientPrescriptionView = ({
  mpi,
  prescriptionId,
  prescriptionData,
  overrideDetails,
  patientData,
  isPrescriptionReview,
  reviewType,
  showTranslation,
}) => {
  const theme = useTheme();
  const [resolveModal, setResolveModal] = useState(false);
  const [resolveDirection, setResolveDirection] = useState(false);
  const [resolvePrescription, setResolvePrescription] = useState(false);
  const isMdScreen = useMediaQuery('(min-width: 960px)');
  const prescriptionList = useSelector(({ patient }) => patient[mpi]?.prescriptions);
  const { payload } = useContext(PrescriptionReviewPayloadContext);
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const prescription = isPrescriptionReview
    ? prescriptionData
    : find(prescriptionList, { prescriptionUUID: prescriptionId });
  const prescriptionOverride = prescription?.prescriptionOverride;
  const enableDrugEditOptions =
    reviewType === PrescriptionReviewStatus.DRUG_PROFILE_REVIEW ||
    reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW;

  const enableSigEditOptions =
    reviewType === PrescriptionReviewStatus.PRESCRIPTION_DIRECTION_REVIEW ||
    reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW;

  const patientDetails = useSelector(({ patient }) => {
    return {
      ...pick(patient[mpi], [
        'firstName',
        'lastName',
        'middleName',
        'gender',
        'email',
        'mobilePhone',
        'dob',
        'mpi',
        'notes',
      ]),
      address: find(patient[mpi]?.addresses, 'isDefault')?.address,
      qs1Id: patient[mpi]?.systemIds?.qs1PatientId,
    };
  }, shallowEqual);

  const closeResolveModal = () => setResolveModal(false);
  const closeDirectionResolveModal = () => setResolveDirection(false);
  const closePrescriptionResolveModal = () => setResolvePrescription(false);

  const openDrugProfileResolveModal = () => setResolveModal(true);
  const openDirectionResolveModal = () => setResolveDirection(true);
  const openPrescriptionResolveModal = () => setResolvePrescription(true);

  const practice = prescription?.practice;
  const patientInfo = isPrescriptionReview ? patientData : patientDetails;

  const medication = prescription?.request?.medication;
  const prescriber = medication?.prescriber;
  const { sigId: editedSigId } = payload?.sig || '';
  const { drugProfileId: editedDrugProfileId } = payload?.drugProfile || '';
  const editedPrescriptionDetails = payload?.prescriptionDetails;
  const prescriptionDrug = prescriptionOverride?.drugProfile?.drugName || prescription.drug;
  const prescriptionStrength = prescriptionOverride?.drugProfile?.strength || prescription.strength;
  const prescriptionForm = (prescriptionOverride?.drugProfile?.form || prescription?.form) ?? 'NA';
  const prescriptionUnit = (prescriptionOverride?.drugProfile?.unit || prescription?.unit) ?? 'NA';
  const drugData = {
    drug: prescriptionDrug,
    strength: prescriptionStrength,
    form: prescriptionForm,
    unit: prescriptionUnit,
  };
  const prescriptionDirection = prescriptionOverride?.sig?.sigText || medication?.instructions;
  const prescriptionPrescriberName =
    prescriptionOverride?.prescriptionDetails?.prescriberName || practice?.prescriberName;
  const prescriptionRefills =
    prescriptionOverride?.prescriptionDetails?.refills || prescription?.refillsRemaining;
  const prescriptionQuantity =
    prescriptionOverride?.prescriptionDetails?.quantity || prescription?.quantityPrescribed;
  const updatedDrug = payload?.drugProfile?.drugName || prescriptionDrug;
  const updatedStrength = payload?.drugProfile?.strength || prescriptionStrength;
  const updatedForm = (payload?.drugProfile?.form || prescriptionForm) ?? 'NA';
  const updatedUnit = (payload?.drugProfile?.unit || prescriptionUnit) ?? 'NA';
  const instructions = medication?.instructions;
  const updatedPrescriberName =
    payload?.prescriptionDetails?.prescriberName || prescriptionPrescriberName;
  const updatedQuantity = payload?.prescriptionDetails?.quantity || prescriptionQuantity;
  const updatedRefills = payload?.prescriptionDetails?.refills || prescriptionRefills;
  const editableOrderDetails = {
    prescriberName: prescriptionPrescriberName,
    refills: prescriptionRefills,
    quantity: prescriptionQuantity,
  };

  const handleSavePrescriptionChanges = () => {
    const overridePrescriptionPayload = {
      input: {
        prescriptionUUID: prescriptionId,
        ...(editedSigId && { sig: { sigId: editedSigId } }),
        ...(editedDrugProfileId && {
          drugProfile: {
            drugProfileId: editedDrugProfileId,
          },
        }),
        ...(!isEmpty(editedPrescriptionDetails) && {
          prescriptionDetails: editedPrescriptionDetails,
        }),
        patientId: mpi,
      },
    };

    showInfoDialog({
      title: 'Save Changes To Prescription',
      message:
        'Please confirm that you have reviewed your changes carefully and are ready to commit the changes to the prescription.',
      confirmButtonText: 'Yes',
      cancelable: true,
      callback: () => {
        setIsLoading(true);
        dispatch(overridePrescription(overridePrescriptionPayload))
          .then((success) => {
            if (!isEmpty(success)) {
              enqueueSnackbar('Prescription has been resolved successfully', {
                variant: 'success',
              });
            } else {
              enqueueSnackbar('Resolve prescription failed', { variant: 'error' });
            }
          })
          .finally(() => setIsLoading(false));
      },
    });
  };

  return (
    <>
      <style>
        {`
        @media print {
          .EditIcon {
            display: none;
          }

          .EditOrder {
            display: none;
          }

          .SaveChanges {
            display: none;
          }
        }
      `}
      </style>

      {enableDrugEditOptions && (
        <ResolveDrugProfileModal
          prescriptionId={prescriptionId}
          open={resolveModal}
          onClose={closeResolveModal}
          drugData={drugData}
          isEditLabel={isPrescriptionReview}
          patientId={mpi}
          reviewType={reviewType}
        />
      )}

      {reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW && (
        <EditOrderDetailsModal
          patientId={mpi}
          open={resolvePrescription}
          prescriptionUUID={prescriptionId}
          onClose={closePrescriptionResolveModal}
          editableOrderDetails={editableOrderDetails}
        />
      )}

      <Grid container alignItems='flex-end'>
        <Typography variant='h3' sx={{ color: lighten(theme.palette.text.primary, 0.3), mr: 8 }}>
          Prescription
        </Typography>
        <Typography variant='h6'>Electronically Transmitted prescription</Typography>
      </Grid>
      {!patientInfo?.qs1Id && (
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          This prescription is created without patient&apos;s QS1 profile
        </Typography>
      )}
      <Divider />

      <InfoDialog />

      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item xs={6}>
          <ItemLabelValue
            label='Order Sent'
            value={
              prescription?.dateOrdered
                ? `${moment
                    .utc(prescription?.dateOrdered, 'MM-DD-YYYY HH:mm')
                    .local()
                    .format('MM-DD-YYYY HH:mm z')} ${momentTimeZone.tz.guess()}`
                : 'N/A'
            }
            fontSize='h6'
            opacity={0.3}
          />

          <ItemLabelValue
            label='Order Number'
            value={prescription?.orderId || 'N/A'}
            fontSize='h6'
            opacity={0.3}
          />
        </Grid>

        <Grid item container xs={6} justifyContent='flex-end' alignItems='center'>
          {reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW && (
            <Button
              sx={{ mr: 2 }}
              variant='outlined'
              color='secondary'
              onClick={openPrescriptionResolveModal}
              className='EditOrder'
            >
              Edit Order Details
            </Button>
          )}

          {reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW && (
            <LoadingButton
              sx={{ mr: 2 }}
              variant='contained'
              color='primary'
              onClick={handleSavePrescriptionChanges}
              className='SaveChanges'
              disabled={isEmpty(omit(payload, ['patientId', 'prescriptionUUID']))}
              loading={isLoading}
            >
              Save Changes
            </LoadingButton>
          )}
        </Grid>
      </Grid>

      {enableDrugEditOptions && (
        <ResolveDrugProfileModal
          prescriptionId={prescriptionId}
          open={resolveModal}
          onClose={closeResolveModal}
          drugData={{
            drug: updatedDrug,
            strength: updatedStrength,
            form: updatedForm,
            unit: updatedUnit,
          }}
          isEditLabel={isPrescriptionReview}
          patientId={mpi}
          reviewType={reviewType}
        />
      )}

      {enableSigEditOptions && (
        <ResolveDirectionModal
          open={resolveDirection}
          onClose={closeDirectionResolveModal}
          drug={updatedDrug}
          directions={prescriptionDirection}
          form={updatedForm}
          strength={updatedStrength}
          prescriptionId={prescriptionId}
          isEditLabel={isPrescriptionReview}
          patientId={mpi}
          reviewType={reviewType}
        />
      )}

      {reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW && (
        <EditOrderDetailsModal
          open={resolvePrescription}
          onClose={closePrescriptionResolveModal}
          editableOrderDetails={{
            prescriberName: updatedPrescriberName,
            quantity: updatedQuantity,
            refills: updatedRefills,
          }}
        />
      )}

      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container direction='column' gap={1}>
            <Typography variant='h6' component='h3' sx={{ fontWeight: 600, mt: 3 }}>
              Patient
            </Typography>

            <ItemLabelValue label='QS1Patient ID' value={patientInfo.qs1Id} />

            <ItemLabelValue
              label='Patient'
              value={
                patientInfo?.middleName
                  ? `${patientInfo?.firstName} ${patientInfo?.middleName} ${patientInfo?.lastName}`
                  : `${patientInfo?.firstName} ${patientInfo?.lastName}`
              }
            />
            <ItemLabelValue label='Phone Number' value={patientInfo.mobilePhone || 'N/A'} />
            <ItemLabelValue label='Email' value={patientInfo.email || 'N/A'} />
            <ItemLabelValue label='Address' value={patientInfo?.address?.addressLine1 || 'N/A'} />
            {patientInfo?.address?.addressLine2 && (
              <ItemLabelValue value={patientInfo?.address?.addressLine2} />
            )}
            <ItemLabelValue
              value={`${patientInfo?.address?.city}, ${patientInfo?.address?.state}, ${patientInfo?.address?.zip}`}
              labelHeight='40px'
            />

            <ItemLabelValue label='DOB' value={moment(patientInfo.dob).format('MM-DD-YYYY')} />
            <ItemLabelValue label='Sex' value={capitalize(patientInfo.gender)} />
            <ItemLabelValue label='Patient ID' value={patientInfo.mpi} />

            <Typography variant='h6' component='h3' sx={{ fontWeight: 600, mt: 3 }}>
              Practice
            </Typography>
            <ItemLabelValue label='Practice name' value={practice?.practiceName || 'N/A'} />
            <ItemLabelValue label='Practice ID' value={practice?.practiceId || 'N/A'} />

            <ItemLabelValue label='Prescriber' value={prescription?.prescriber || 'N/A'} />

            {showTranslation && prescriptionOverride?.prescriptionDetails?.prescriberName && (
              <OverrideItemLabelValue
                labelName='Prescription Provider'
                pharmacistName={overrideDetails?.prescriberName?.user}
                updatedTime={overrideDetails?.prescriberName?.editedTimestamp}
                labelValue={updatedPrescriberName}
              />
            )}

            <ItemLabelValue
              label='Electronically Signed by'
              value={practice?.electronicallySignedBy || 'N/A'}
            />
            <ItemLabelValue label='Supervisor' value={practice?.supervisor || 'N/A'} />
            <ItemLabelValue label='Phone' value={prescriber?.phone || 'N/A'} />
            <ItemLabelValue label='Fax' value={prescriber?.fax || 'N/A'} />
            <ItemLabelValue label='Address' value={prescriber?.addressLine1 || 'N/A'} />
            {prescriber?.addressLine2 && <ItemLabelValue value={prescriber.addressLine2} />}
            {prescriber?.addressLine1 && (
              <ItemLabelValue
                value={`${prescriber?.city}, ${prescriber?.state} , ${prescriber?.zip}`}
              />
            )}
            <ItemLabelValue label='DEA' value={practice?.prescriberDea || 'N/A'} />
            <ItemLabelValue label='NPI' value={practice?.prescriberNpi || 'N/A'} />
            <ItemLabelValue label='Ordering Agent' value={practice?.orderingAgent || 'N/A'} />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          sx={{ ...(isMdScreen && { borderLeft: '1px solid rgba(0, 0, 0, 0.12)', px: 3 }) }}
        >
          <Grid container direction='column' gap={1}>
            <Typography variant='h6' component='h3' sx={{ fontWeight: 600, mt: 3 }}>
              Drug
            </Typography>
            <ItemLabelValue label='Prescriber Description' value={prescription?.drug ?? 'N/A'} />

            {showTranslation &&
              (enableDrugEditOptions || prescriptionOverride?.drugProfile?.drugName) && (
                <OverrideItemLabelValue
                  labelName='Pharmacy Dispensed Medication'
                  labelValue={updatedDrug}
                  pharmacistName={overrideDetails?.drugProfile?.user}
                  updatedTime={overrideDetails?.drugProfile?.editedTimestamp}
                  handleModel={openDrugProfileResolveModal}
                  showEditOption={enableDrugEditOptions}
                />
              )}

            <ItemLabelValue label='Strength' value={updatedStrength ?? 'N/A'} />

            <ItemLabelValue label='Drug Form' value={updatedForm ?? 'N/A'} />

            <ItemLabelValue label='Direction' value={instructions || 'N/A'} />

            {showTranslation && (enableSigEditOptions || prescriptionOverride?.sig?.sigText) && (
              <OverrideItemLabelValue
                labelName='Sig'
                labelValue={payload?.sig?.sig || prescriptionOverride?.sig?.sigText || 'N/A'}
                pharmacistName={overrideDetails?.sig?.user}
                updatedTime={overrideDetails?.sig?.editedTimestamp}
                handleModel={openDirectionResolveModal}
                showEditOption={enableSigEditOptions}
              />
            )}
            <ItemLabelValue label='Notes' value={prescription?.rxNotes || 'N/A'} />

            <ItemLabelValue label='Rx Number' value={prescription?.rxNumber || 'N/A'} />
            <ItemLabelValue label='Quantity' value={prescription?.quantityPrescribed} />

            {showTranslation && prescriptionOverride?.prescriptionDetails?.quantity && (
              <OverrideItemLabelValue
                labelName='Label Quantity'
                pharmacistName={overrideDetails?.quantity?.user}
                updatedTime={overrideDetails?.quantity?.editedTimestamp}
                labelValue={updatedQuantity || 'N/A'}
              />
            )}

            <ItemLabelValue label='Units' value={prescriptionUnit || 'N/A'} />
            <ItemLabelValue label='Days Supply' value={prescription?.daysSupply || 'N/A'} />
            <ItemLabelValue label='Refills' value={prescription?.refillsRemaining || 'N/A'} />

            {showTranslation && prescriptionOverride?.prescriptionDetails?.refills && (
              <OverrideItemLabelValue
                labelName='Label Refills'
                pharmacistName={overrideDetails?.refills?.user}
                updatedTime={overrideDetails?.refills?.editedTimestamp}
                labelValue={updatedRefills || 'N/A'}
              />
            )}

            <ItemLabelValue label='DAW' value={prescription?.daw || 'N/A'} />
            <ItemLabelValue
              label='Date Written'
              value={
                prescription?.writtenDate
                  ? moment(prescription.writtenDate).format('MM-DD-YYYY')
                  : 'N/A'
              }
            />
            <ItemLabelValue
              label='Expiration Date'
              value={
                prescription?.expirationDate
                  ? moment(prescription.expirationDate).format('MM-DD-YYYY')
                  : 'N/A'
              }
            />
            <ItemLabelValue
              label='LastFill Date'
              value={
                prescription?.lastFillDate
                  ? moment(prescription.lastFillDate).format('MM-DD-YYYY')
                  : 'N/A'
              }
            />
            <Typography variant='h6' component='h3' sx={{ fontWeight: 600, mt: 3 }}>
              Filling Pharmacy
            </Typography>
            <ItemLabelValue label='Pharmacy' value='SmartScripts' />
            <ItemLabelValue label='Address' value={smartscriptsPharmacyAddress.line1} />
            <ItemLabelValue value={smartscriptsPharmacyAddress.line2} />
            <ItemLabelValue
              value={`${smartscriptsPharmacyAddress.city}, ${smartscriptsPharmacyAddress.state} , ${smartscriptsPharmacyAddress.zip}`}
              labelHeight='40px'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PatientPrescriptionView;

const editedFieldPropTypes = PropTypes.shape({
  user: PropTypes.string,
  editedTimestamp: PropTypes.string,
});

PatientPrescriptionView.propTypes = {
  mpi: PropTypes.string,
  prescriptionId: PropTypes.string,
  prescriptionData: PropTypes.shape({
    practice: PropTypes.shape({
      practiceName: PropTypes.string,
      practiceId: PropTypes.string,
      prescriberName: PropTypes.string,
      electronicallySignedBy: PropTypes.string,
      supervisor: PropTypes.string,
      prescriberDea: PropTypes.string,
      prescriberNpi: PropTypes.string,
      orderingAgent: PropTypes.string,
    }),
    writtenDate: PropTypes.string,
    expirationDate: PropTypes.string,
    lastFillDate: PropTypes.string,
    dateOrdered: PropTypes.string,
    orderId: PropTypes.string,
    drug: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    rxNotes: PropTypes.string,
    rxNumber: PropTypes.string,
    quantityPrescribed: PropTypes.string,
    drugUnit: PropTypes.string,
    daysSupply: PropTypes.string,
    refillsRemaining: PropTypes.string,
    daw: PropTypes.string,
    prescriptionOverride: PropTypes.shape({
      prescriptionDetails: PropTypes.shape({
        prescriberName: PropTypes.string,
        quantity: PropTypes.string,
        refills: PropTypes.string,
      }),
      drugProfile: PropTypes.shape({
        drugName: PropTypes.string,
        strength: PropTypes.string,
      }),
      sig: PropTypes.shape({
        sigText: PropTypes.string,
      }),
    }),
    request: PropTypes.shape({
      medication: PropTypes.shape({
        prescriber: PropTypes.shape({
          phone: PropTypes.string,
          fax: PropTypes.string,
          addressLine1: PropTypes.string,
          addressLine2: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
          zip: PropTypes.string,
        }),
        instructions: PropTypes.string.isRequired,
      }),
    }),
  }),
  patientData: PropTypes.shape({
    qs1Id: PropTypes.string,
    middleName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
    dob: PropTypes.string,
    gender: PropTypes.string,
    mpi: PropTypes.string,
  }),
  overrideDetails: PropTypes.shape({
    prescriberName: editedFieldPropTypes,
    drugProfile: editedFieldPropTypes,
    quantity: editedFieldPropTypes,
    refills: editedFieldPropTypes,
    sig: editedFieldPropTypes,
  }),
  isPrescriptionReview: PropTypes.bool,
  reviewType: PropTypes.string,
  showTranslation: PropTypes.bool.isRequired,
};

PatientPrescriptionView.defaultProps = {
  mpi: null,
  prescriptionId: null,
  prescriptionData: null,
  patientData: null,
  overrideDetails: null,
  isPrescriptionReview: false,
  reviewType: '',
};

PatientPrescriptionView.propTypes = {
  mpi: PropTypes.string,
  prescriptionId: PropTypes.string,
  overrideDetails: PropTypes.shape({
    prescriberName: PropTypes.shape({ user: PropTypes.string, editedTimestamp: PropTypes.string }),
    drugProfile: PropTypes.shape({ user: PropTypes.string, editedTimestamp: PropTypes.string }),
    quantity: PropTypes.shape({ user: PropTypes.string, editedTimestamp: PropTypes.string }),
    refills: PropTypes.shape({ user: PropTypes.string, editedTimestamp: PropTypes.string }),
    sig: PropTypes.shape({ user: PropTypes.string, editedTimestamp: PropTypes.string }),
  }),
};
