import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { replace } from 'lodash';

import TextField from 'common/forms/TextField';
import {
  validateCreditCardNumber,
  validateExpirationDate,
  validateValueWithRegex,
} from 'common/forms/formValidations';

const formatCreditCardNumber = (value) => {
  if (!value) return '';

  return replace(value, /[^0-9]/g, '')
    .match(/.{1,4}/g)
    .join(' ');
};

const parseCreditCardNumber = (value) => replace(value, /[^0-9]/g, '');

const formatExpiration = (value) => {
  if (!value) return '';

  return replace(value, /[^0-9]/g, '')
    .match(/.{1,2}/g)
    .join('/');
};

const CreditCardForm = () => {
  return (
    <Box sx={{ width: '100%', py: 2 }}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <TextField
            id='CreditCardForm-cardholderName-input'
            name='cardholderName'
            label='Cardholder Name'
            required
            maxLength={50}
          />

          <TextField
            id='CreditCardForm-creditCardNumber-input'
            name='cardNumber'
            label='Card Number'
            required
            parse={parseCreditCardNumber}
            format={formatCreditCardNumber}
            validations={[validateCreditCardNumber('Invalid Card Number')]}
            maxLength={23}
          />
        </Grid>

        <Grid xs={12} sm={4}>
          <TextField
            id='CreditCardForm-expiration-input'
            name='expiration'
            label='Expiration (mm/yy)'
            validations={[validateExpirationDate('Invalid Expiration')]}
            format={formatExpiration}
            required
            maxLength={5}
          />
        </Grid>

        <Grid xs={12} sm={4}>
          <TextField
            id='CreditCardForm-cvv-input'
            name='cvv'
            label='Security Code'
            validations={[validateValueWithRegex('Invalid CVV', /^[0-9]{3,4}$/)]}
            required
            maxLength={4}
          />
        </Grid>

        <Grid xs={12} sm={4}>
          <TextField
            id='CreditCardForm-zip-input'
            name='billingPostalCode'
            label='Zip Code'
            required
            validations={[validateValueWithRegex('Invalid Zip', /^\d{5}$/)]}
            maxLength={5}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreditCardForm;
