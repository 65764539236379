/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import {
  every,
  find,
  first,
  isArray,
  isNil,
  map,
  mergeWith,
  omit,
  pick,
  reduce,
  reject,
  reverse,
  some,
  sortBy,
  sortedUniqBy,
} from 'lodash';

import {
  addGuarantorApi,
  addPatientAddressApi,
  addPatientApi,
  addPatientInsuranceApi,
  addPatientMedicalHistoryApi,
  addPatientMedicationApi,
  addPatientPartnersApi,
  addPatientPharmacyApi,
  addPatientProviderApi,
  associatePatientWithExternalProfileApi,
  createLineItemNoteApi,
  createPatientCommentApi,
  deactivateInsuranceApi,
  deactivatePatientProviderApi,
  deleteGuarantorApi,
  deletePatientAddressApi,
  deletePatientCommentApi,
  deletePatientMedicationApi,
  deletePatientPartnerApi,
  deletePatientPharmacyApi,
  editOrderShipmentApi,
  editPatientApi,
  editPatientPrescriptionApi,
  getAuthorizationsApi,
  getFullPatientDetailsApi,
  getGuarantorsApi,
  getPatientActionItemsApi,
  getPatientAddressesApi,
  getPatientApi,
  getPatientInsurancesApi,
  getPatientMedicalHistoryApi,
  getPatientPharmaciesApi,
  getPatientProvidersApi,
  getPatientUsersApi,
  listLineItemNotesApi,
  listPatientCommentsApi,
  listPatientInvoicesApi,
  listPatientMedicationsApi,
  listPatientOrderShipmentsApi,
  listPatientOrdersApi,
  listPatientPrescriptionsApi,
  noteActionApi,
  onboardPatientApi,
  resetPasswordApi,
  saveTestClaimResultApi,
  searchQs1ProfileApi,
  setPartnerExternalIdApi,
  updateGuarantorApi,
  updatePatientAddressApi,
  updatePatientAuthorizationsApi,
  updatePatientInsuranceApi,
  updatePatientMedicationApi,
  updatePatientPharmacyApi,
  updatePatientProviderApi,
} from 'api/patient';
import { patientActions } from 'store/slices/patientSlice';
import {
  assertQs1RxNumberApi,
  completeEntryApi,
  controlledSubstancesCheckApi,
  copayResultCheckApi,
  prescriberClarificationCheckApi,
  profitabilityResultCheckApi,
  reviewLineItemApi,
  updateRequestLineItemStateApi,
  verifyRequestLineItemApi,
} from 'api/requests';
import {
  cancelOrderApi,
  createOrderShipmentApi,
  manageProductFulfillmentApi,
  orderNeedsReviewApi,
  prescriptionNeedsReviewAPI,
  reverifyOrderApi,
  updateOrderAddressApi,
  verifyOrderApi,
} from 'api/order';
import { ContextType } from 'common/constants/notes';
import errorHeaders from 'common/constants/errorHeaders';
import { workQueueActions } from 'store/slices/workQueueSlice';
import { handleError } from './errorHandlerThunks';

export const addPatient =
  ({ patientData }) =>
  (dispatch) => {
    return addPatientApi({ patientData })
      .then((patient) => {
        dispatch(patientActions.loadPatientData({ patient }));

        return patient.mpi;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const editPatient =
  ({ patientData }) =>
  (dispatch) => {
    return editPatientApi({ patientData })
      .then(() => {
        dispatch(patientActions.loadPatientData({ patient: patientData }));
        dispatch(
          patientActions.updatePatientNotificationPreference({
            phone: patientData.preferencesState.phone,
            email: patientData.preferencesState.email,
            mpi: patientData.mpi,
          })
        );

        return true;
      })
      .catch((error) => {
        return first(error.response?.errors)?.extensions?.code ===
          errorHeaders.QS1_VALIDATION_FAILED
          ? dispatch(handleError({ error, heading: 'QS1 Id Not Found' }))
          : dispatch(handleError({ error }));
      });
  };

export const listPatientComments =
  ({ mpi, offset, searchText }) =>
  (dispatch, getState) => {
    const oldComments = getState().patient[mpi]?.comments || [];

    return listPatientCommentsApi({ mpi, offset, searchText })
      .then((comments) => {
        const hasMoreComments = !!comments?.length;

        return dispatch(
          patientActions.loadComments({
            mpi,
            comments: sortedUniqBy(
              reverse(sortBy([...oldComments, ...comments], ['createdAt'])),
              'commentId'
            ),
            hasMoreComments,
          })
        );
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const addPatientComments =
  ({ mpi, message }) =>
  (dispatch, getState) => {
    const oldComments = getState().patient[mpi]?.comments || [];
    const hasMoreComments = getState().patient[mpi]?.hasMoreComments;

    return createPatientCommentApi({ mpi, message, contextId: mpi })
      .then(({ comment }) => {
        return dispatch(
          patientActions.loadComments({
            mpi,
            comments: [comment, ...oldComments],
            hasMoreComments,
          })
        );
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const deletePatientComment =
  ({ mpi, patientCommentId }) =>
  (dispatch, getState) => {
    const { comments, hasMoreComments } = getState().patient[mpi];

    dispatch(
      patientActions.deleteComment({
        mpi,
        patientCommentId,
      })
    );

    return deletePatientCommentApi({ patientCommentId }).catch((error) => {
      dispatch(handleError({ error }));
      dispatch(
        patientActions.loadComments({
          mpi,
          comments,
          hasMoreComments,
        })
      );
    });
  };

export const getPatient =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientApi({ mpi })
      .then(({ addresses, ...patient }) => {
        dispatch(patientActions.loadPatientData({ patient }));
        dispatch(patientActions.loadAddresses({ addresses, mpi }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const addGuarantor =
  ({ guarantor }) =>
  (dispatch, getState) => {
    const guarantors = getState().patient[guarantor.mpi]?.guarantors || [];

    return addGuarantorApi({ guarantor })
      .then((newGuarantor) => {
        dispatch(
          patientActions.loadGuarantors({
            guarantors: [...guarantors, newGuarantor],
            mpi: guarantor.mpi,
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getGuarantors =
  ({ mpi }) =>
  (dispatch) => {
    return getGuarantorsApi({ mpi })
      .then((guarantors) => {
        dispatch(patientActions.loadGuarantors({ guarantors, mpi }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const updateGuarantor =
  ({ guarantor }) =>
  (dispatch, getState) => {
    const guarantors = getState().patient[guarantor.mpi]?.guarantors || [];

    return updateGuarantorApi({ guarantor })
      .then((updatedGuarantor) => {
        dispatch(
          patientActions.loadGuarantors({
            mpi: guarantor.mpi,
            guarantors: [
              ...reject(guarantors, { guarantorId: updatedGuarantor.guarantorId }),
              updatedGuarantor,
            ],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(
          patientActions.loadGuarantors({
            mpi: guarantor.mpi,
            guarantors,
          })
        );
        dispatch(handleError({ error }));
        return false;
      });
  };

export const addPatientMedication =
  ({ medicationData }) =>
  (dispatch, getState) => {
    const medications = getState().patient[medicationData.mpi]?.medications || [];

    return addPatientMedicationApi({ medicationData })
      .then((medication) => {
        dispatch(
          patientActions.loadMedications({
            mpi: medicationData.mpi,
            medications: [medication, ...medications],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deleteGuarantor =
  ({ guarantorId, mpi }) =>
  (dispatch, getState) => {
    const guarantors = getState().patient[mpi]?.guarantors || [];

    dispatch(
      patientActions.loadGuarantors({
        mpi,
        guarantors: reject(guarantors, { guarantorId }),
      })
    );

    return deleteGuarantorApi({ mpi, guarantorId }).catch((error) => {
      dispatch(patientActions.loadGuarantors({ mpi, guarantors }));
      dispatch(handleError({ error }));
    });
  };

export const updatePatientMedication =
  ({ medication }) =>
  (dispatch, getState) => {
    const medications = getState().patient[medication.mpi]?.medications || [];

    return updatePatientMedicationApi({ medication })
      .then((updatedMedication) => {
        dispatch(
          patientActions.loadMedications({
            mpi: medication.mpi,
            medications: [
              ...reject(medications, {
                patientMedicationId: updatedMedication.patientMedicationId,
              }),
              updatedMedication,
            ],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(
          patientActions.loadMedications({
            mpi: medication.mpi,
            medications,
          })
        );
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deletePatientMedication =
  ({ patientMedicationId, mpi }) =>
  (dispatch, getState) => {
    const medications = getState().patient[mpi]?.medications || [];

    dispatch(
      patientActions.loadMedications({
        mpi,
        medications: reject(medications, { patientMedicationId }),
      })
    );

    return deletePatientMedicationApi({ patientMedicationId, mpi }).catch((error) => {
      dispatch(patientActions.loadMedications({ mpi, medications }));
      dispatch(handleError({ error }));
    });
  };

export const listPatientMedications =
  ({ mpi }) =>
  (dispatch) => {
    return listPatientMedicationsApi({ mpi })
      .then((medications) => dispatch(patientActions.loadMedications({ mpi, medications })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const listPatientPrescriptions =
  ({ mpi, forceRefresh }) =>
  (dispatch) => {
    return listPatientPrescriptionsApi({ mpi, forceRefresh })
      .then((prescriptions) => dispatch(patientActions.loadPrescriptions({ mpi, prescriptions })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const getPatientMedicalHistory =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientMedicalHistoryApi({ mpi })
      .then(({ medicalHistory }) =>
        dispatch(patientActions.loadMedicalHistory({ mpi, medicalHistory }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const addPatientMedicalHistory =
  ({ newMedicalHistory }) =>
  (dispatch, getState) => {
    const existingMedicalHistory = getState().patient[newMedicalHistory.mpi].medicalHistory;

    return addPatientMedicalHistoryApi({ newMedicalHistory })
      .then(({ medicalHistory }) => {
        dispatch(
          patientActions.loadMedicalHistory({
            mpi: newMedicalHistory.mpi,
            medicalHistory: mergeWith({ ...existingMedicalHistory }, medicalHistory, (dest, src) =>
              isArray(dest) && !isNil(src) ? dest.concat(src) : undefined
            ),
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getAuthorizations =
  ({ mpi }) =>
  (dispatch) => {
    return getAuthorizationsApi({ mpi })
      .then((authorizationObj) =>
        dispatch(
          patientActions.loadAuthorizations({
            mpi,
            authorization: authorizationObj?.authorization || null,
          })
        )
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const updatePatientAuthorizations =
  ({ mpi, newAuthorization }) =>
  (dispatch) => {
    return updatePatientAuthorizationsApi({ mpi, authorization: newAuthorization })
      .then(({ authorization }) => {
        dispatch(patientActions.loadAuthorizations({ mpi, authorization }));
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const addPatientInsurance =
  ({ insurance }) =>
  (dispatch, getState) => {
    const insurances = getState().patient[insurance.mpi]?.insurances || [];

    return addPatientInsuranceApi({ insurance })
      .then((newInsurance) => {
        dispatch(
          patientActions.loadInsurances({
            mpi: insurance.mpi,
            insurances: [...insurances, newInsurance],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getPatientInsurances =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientInsurancesApi({ mpi })
      .then((insurances) => {
        dispatch(patientActions.loadInsurances({ mpi, insurances }));
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const updatePatientInsurance =
  ({ insurance }) =>
  (dispatch, getState) => {
    const insurances = getState().patient[insurance.mpi]?.insurances || [];

    return updatePatientInsuranceApi({ insurance })
      .then((updatedInsurance) => {
        dispatch(
          patientActions.loadInsurances({
            mpi: insurance.mpi,
            insurances: [
              ...reject(insurances, { patientInsuranceId: updatedInsurance.patientInsuranceId }),
              updatedInsurance,
            ],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(getPatientInsurances({ mpi: insurance.mpi }));
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deactivateInsurance =
  ({ patientInsuranceId, mpi }) =>
  (dispatch, getState) => {
    const insurances = getState().patient[mpi]?.insurances || [];
    const oldInsuranceState = find(insurances, { patientInsuranceId });

    dispatch(
      patientActions.loadInsurances({
        mpi,
        insurances: [
          ...reject(insurances, { patientInsuranceId: oldInsuranceState.patientInsuranceId }),
          { ...oldInsuranceState, isActive: false, priority: null },
        ],
      })
    );

    return deactivateInsuranceApi({ patientInsuranceId }).catch((error) => {
      dispatch(getPatientInsurances({ mpi }));
      dispatch(handleError({ error }));
    });
  };

export const activateInsurance =
  ({ patientInsuranceId, mpi }) =>
  (dispatch, getState) => {
    const insurances = getState().patient[mpi]?.insurances || [];
    const oldInsuranceState = find(insurances, { patientInsuranceId });
    const insurance = { ...oldInsuranceState, isActive: true, mpi };

    dispatch(
      patientActions.loadInsurances({
        mpi,
        insurances: [
          ...reject(insurances, { patientInsuranceId: oldInsuranceState.patientInsuranceId }),
          insurance,
        ],
      })
    );

    return updatePatientInsuranceApi({
      insurance: omit(insurance, ['verification', 'modifiedTimestamp']),
    }).catch((error) => {
      dispatch(getPatientInsurances({ mpi }));
      dispatch(handleError({ error }));
    });
  };

export const onboardPatient =
  ({ onboardPatientInput }) =>
  (dispatch) => {
    return onboardPatientApi({ onboardPatientInput })
      .then(({ patient, authorization }) => {
        dispatch(patientActions.loadPatientData({ patient }));
        dispatch(patientActions.loadAuthorizations({ mpi: patient.mpi, authorization }));
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getFullPatientDetails =
  ({ mpi }) =>
  (dispatch) => {
    return getFullPatientDetailsApi({ mpi })
      .then(({ guarantors, insurances, medications, authorization }) => {
        dispatch(patientActions.loadInsurances({ mpi, insurances }));
        dispatch(patientActions.loadAuthorizations({ mpi, authorization }));
        dispatch(patientActions.loadMedications({ mpi, medications }));
        dispatch(patientActions.loadGuarantors({ guarantors, mpi }));
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const addPatientProvider =
  ({ provider }) =>
  (dispatch, getState) => {
    const providers = getState().patient[provider.mpi]?.providers || [];

    return addPatientProviderApi({ provider })
      .then((newProvider) => {
        dispatch(
          patientActions.loadProviders({
            mpi: provider.mpi,
            providers: [...providers, newProvider],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getPatientProviders =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientProvidersApi({ mpi })
      .then((providers) => dispatch(patientActions.loadProviders({ mpi, providers })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const updatePatientProvider =
  ({ provider, mpi }) =>
  (dispatch, getState) => {
    const providers = getState().patient[mpi]?.providers || [];

    return updatePatientProviderApi({ provider })
      .then((updatedProvider) => {
        dispatch(
          patientActions.loadProviders({
            mpi,
            providers: [
              ...reject(providers, { patientProviderId: updatedProvider.patientProviderId }),
              updatedProvider,
            ],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(getPatientProviders({ mpi }));
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deactivatePatientProvider =
  ({ patientProviderId, mpi }) =>
  (dispatch, getState) => {
    const providers = getState().patient[mpi]?.providers || [];
    const oldProviderState = find(providers, { patientProviderId });

    dispatch(
      patientActions.loadProviders({
        mpi,
        providers: [
          ...reject(providers, { patientProviderId: oldProviderState.patientProviderId }),
          { ...oldProviderState, isActive: false },
        ],
      })
    );

    return deactivatePatientProviderApi({ patientProviderId }).catch((error) => {
      dispatch(patientActions.loadProviders({ mpi, providers }));
      dispatch(handleError({ error }));
    });
  };

export const activatePatientProvider =
  ({ patientProviderId, mpi }) =>
  (dispatch, getState) => {
    const providers = getState().patient[mpi]?.providers || [];
    const oldProviderState = find(providers, { patientProviderId });
    const provider = { ...oldProviderState, isActive: true };

    dispatch(
      patientActions.loadProviders({
        mpi,
        providers: [
          ...reject(providers, { patientProviderId: oldProviderState.patientProviderId }),
          provider,
        ],
      })
    );

    return updatePatientProviderApi({
      provider,
    }).catch((error) => {
      dispatch(getPatientProviders({ mpi }));
      dispatch(handleError({ error }));
    });
  };

export const addPatientPharmacy =
  ({ pharmacy }) =>
  (dispatch, getState) => {
    const pharmacies = getState().patient[pharmacy.mpi]?.pharmacies || [];

    return addPatientPharmacyApi({ pharmacy })
      .then((newPharmacy) => {
        dispatch(
          patientActions.loadPharmacies({
            mpi: pharmacy.mpi,
            pharmacies: [...pharmacies, newPharmacy],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getPatientPharmacies =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientPharmaciesApi({ mpi })
      .then((pharmacies) => dispatch(patientActions.loadPharmacies({ mpi, pharmacies })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const updatePatientPharmacy =
  ({ pharmacy, mpi }) =>
  (dispatch, getState) => {
    const pharmacies = getState().patient[mpi]?.pharmacies || [];

    return updatePatientPharmacyApi({ pharmacy })
      .then((updatedPharmacy) => {
        dispatch(
          patientActions.loadPharmacies({
            mpi,
            pharmacies: [
              ...reject(pharmacies, { patientPharmacyId: updatedPharmacy.patientPharmacyId }),
              updatedPharmacy,
            ],
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(getPatientPharmacies({ mpi }));
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deletePatientPharmacy =
  ({ patientPharmacyId, mpi }) =>
  (dispatch, getState) => {
    const pharmacies = getState().patient[mpi]?.pharmacies || [];

    dispatch(
      patientActions.loadPharmacies({
        mpi,
        pharmacies: reject(pharmacies, { patientPharmacyId }),
      })
    );

    return deletePatientPharmacyApi({ patientPharmacyId }).catch((error) => {
      dispatch(patientActions.loadPharmacies({ mpi, pharmacies }));
      dispatch(handleError({ error }));
    });
  };

export const listPatientInvoices =
  ({ mpi, page }) =>
  (dispatch) => {
    dispatch(patientActions.updateInvoicesCurrentPage({ mpi, page }));

    return listPatientInvoicesApi({ mpi, page })
      .then(({ count, invoices }) => {
        dispatch(patientActions.loadInvoices({ mpi, page, invoices, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const saveTestClaim =
  ({ saveTestClaimResultInput }) =>
  (dispatch, getState) => {
    const insurances = getState().patient[saveTestClaimResultInput.mpi]?.insurances || [];

    return saveTestClaimResultApi({
      saveTestClaimResultInput,
    })
      .then(({ patientDemographics, systemIds, lastUsedInsurance }) => {
        dispatch(
          patientActions.loadPatientData({
            patient: {
              mpi: saveTestClaimResultInput.mpi,
              status: patientDemographics.status,
              systemIds,
              lastUsedInsurance: pick(lastUsedInsurance, [
                'planName',
                'planType',
                'patientInsuranceId',
              ]),
            },
          })
        );

        dispatch(
          patientActions.loadInsurances({
            mpi: saveTestClaimResultInput.mpi,
            insurances: [
              ...reject(insurances, { patientInsuranceId: lastUsedInsurance.patientInsuranceId }),
              lastUsedInsurance,
            ],
          })
        );
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const addPatientPartners =
  ({ mpi, partners }) =>
  (dispatch, getState) => {
    const { partnerUUID: partnerId, partnerExternalId: identityValue } = first(partners);
    const partnerIds = getState().patient[mpi]?.partnerIds || [];

    return addPatientPartnersApi({ mpi, partners })
      .then(() => {
        dispatch(
          patientActions.loadPatientData({
            patient: {
              mpi,
              partnerIds: [
                ...partnerIds,
                {
                  partnerId,
                  identityValue,
                },
              ],
            },
          })
        );
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const updatePatientPartner =
  ({ mpi, partnerId, identityValue }) =>
  (dispatch) => {
    return setPartnerExternalIdApi({ mpi, partnerId, identityValue })
      .then((updatedPatientPartner) => {
        dispatch(patientActions.updatePartner({ updatedPatientPartner, mpi }));
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const deletePatientPartner =
  ({ partnerUUID, mpi }) =>
  (dispatch, getState) => {
    const partnerIds = getState().patient[mpi]?.partnerIds || [];
    const updatedPartnerIds = reject(partnerIds, { partnerId: partnerUUID });
    dispatch(
      patientActions.loadPatientData({
        patient: {
          mpi,
          partnerIds: updatedPartnerIds,
        },
      })
    );

    return deletePatientPartnerApi({ partnerUUID, mpi }).catch((error) => {
      dispatch(
        patientActions.loadPatientData({
          patient: {
            mpi,
            partnerIds,
          },
        })
      );
      dispatch(handleError({ error }));
    });
  };

export const getPatientActionItems =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientActionItemsApi({ mpi })
      .then(({ requests, orders }) => {
        dispatch(
          patientActions.loadRequests({
            mpi,
            requests,
          })
        );

        dispatch(patientActions.loadOrders({ mpi, orders }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const controlledSubstancesCheck =
  ({ mpi, requestId, checkInput }) =>
  (dispatch) => {
    return controlledSubstancesCheckApi({ checkInput })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const reviewLineItem =
  ({ mpi, lineItemId, reviewResult, reasonForRejection, qs1RxNumber, requestId }) =>
  (dispatch) => {
    return reviewLineItemApi({ mpi, lineItemId, reviewResult, reasonForRejection, qs1RxNumber })
      .then((requestItem) => {
        dispatch(
          patientActions.updateTransferRequestStatus({
            mpi,
            requestId,
            requestItem,
          })
        );

        if (requestItem.status === 'PRODUCT_FULFILLMENT' && reviewResult) {
          dispatch(patientActions.addLineItemsToOrder({ mpi, requestItem }));
        }
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const prescriberClarificationCheck =
  ({ mpi, requestId, checkInput }) =>
  (dispatch) => {
    return prescriberClarificationCheckApi({ checkInput })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const copayResultCheck =
  ({ mpi, requestId, checkInput }) =>
  (dispatch) => {
    return copayResultCheckApi({ checkInput })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const profitabilityResultCheck =
  ({ mpi, requestId, checkInput }) =>
  (dispatch) => {
    return profitabilityResultCheckApi({ checkInput })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const verifyRequestLineItem =
  ({ input, requestId }) =>
  (dispatch) => {
    return verifyRequestLineItemApi({ input })
      .then((requestItem) => {
        dispatch(
          patientActions.updateTransferRequestStatus({
            mpi: input.mpi,
            requestId,
            requestItem,
          })
        );

        if (!requestItem.taggedForReview) {
          dispatch(patientActions.addLineItemsToOrder({ mpi: input.mpi, requestItem }));
        }

        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const getPatientAddresses =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientAddressesApi({ mpi })
      .then((addresses) => {
        dispatch(patientActions.loadAddresses({ mpi, addresses }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

const buildPatientAddress = ({ existingAddresses, isNewAddressDefaulted }) => {
  const updatedExistingAddress = map(existingAddresses, ({ isDefault, ...existingAddress }) => ({
    ...existingAddress,
    isDefault: isNewAddressDefaulted ? false : isDefault,
  }));

  return updatedExistingAddress;
};

export const addPatientAddress =
  ({ mpi, address }) =>
  (dispatch, getState) => {
    return addPatientAddressApi({ mpi, address })
      .then((addAddressResponse) => {
        // eslint-disable-next-line no-underscore-dangle
        if (addAddressResponse?.__typename === 'Addresses') {
          return addAddressResponse.addresses;
        }

        const existingAddresses = getState().patient[mpi]?.addresses || [];
        const isNewAddressDefaulted = address.isDefault;
        const updatedExistingAddress = buildPatientAddress({
          existingAddresses,
          isNewAddressDefaulted,
        });

        dispatch(
          patientActions.loadAddresses({
            mpi,
            addresses: [addAddressResponse, ...updatedExistingAddress],
          })
        );

        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));

        return false;
      });
  };

export const deletePatientAddress =
  ({ mpi, patientAddressId }) =>
  (dispatch, getState) => {
    const existingAddresses = getState().patient[mpi]?.addresses;
    const updatedAddresses = reject(existingAddresses, { patientAddressId });

    dispatch(
      patientActions.loadAddresses({
        mpi,
        addresses: updatedAddresses,
      })
    );

    return deletePatientAddressApi({ mpi, patientAddressId }).catch((error) => {
      dispatch(
        patientActions.loadAddresses({
          mpi,
          addresses: existingAddresses,
        })
      );

      dispatch(handleError({ error }));
    });
  };

export const updatePatientAddress =
  ({ mpi, address }) =>
  (dispatch, getState) => {
    const patientAddresses = reject(getState().patient[mpi]?.addresses, {
      patientAddressId: address.patientAddressId,
    });

    return updatePatientAddressApi({ mpi, address })
      .then((updatedAddress) => {
        dispatch(
          patientActions.loadAddresses({
            mpi,
            addresses: [updatedAddress, ...patientAddresses],
          })
        );

        return true;
      })
      .catch((error) => {
        dispatch(getPatientAddresses({ mpi }));
        dispatch(handleError({ error }));

        return false;
      });
  };

export const defaultPatientAddress =
  ({ mpi, patientAddressId }) =>
  (dispatch, getState) => {
    const patientAddresses = getState().patient[mpi]?.addresses;

    const updatedAddresses = map(patientAddresses, (patientAddress) => ({
      ...patientAddress,
      isDefault: patientAddress.patientAddressId === patientAddressId,
    }));

    dispatch(
      patientActions.loadAddresses({
        mpi,
        addresses: updatedAddresses,
      })
    );

    return updatePatientAddressApi({ mpi, address: { patientAddressId, isDefault: true } }).catch(
      (error) => {
        patientActions.loadAddresses({
          mpi,
          addresses: patientAddresses,
        });

        dispatch(handleError({ error }));
      }
    );
  };

const hasPinnedNote = (notes) => some(notes, ['noteState', 'PIN']);

export const getExisitingNotes = ({ patientRecord, lineItemId, vaultId, vaultType }) => {
  return vaultType === ContextType.LINE_ITEM
    ? find(patientRecord?.orders?.find(({ orderId }) => orderId === vaultId)?.lineItems || [], {
        lineItemId,
      })?.notes || []
    : patientRecord?.requests?.[vaultId]?.transferRequest?.[lineItemId]?.notes || [];
};

export const addLineItemNote =
  ({ mpi, content, commentContext, vaultId, vaultType }) =>
  (dispatch, getState) => {
    const patientRecord = getState().patient[mpi];
    const existingNotes = getExisitingNotes({
      patientRecord,
      lineItemId: commentContext.id,
      vaultId,
      vaultType,
    });

    return createLineItemNoteApi({ mpi, content, commentContext })
      .then((newNote) => {
        const updatedNotes = [newNote, ...existingNotes];

        dispatch(
          patientActions.loadLineItemNotes({
            mpi,
            notes: updatedNotes,
            vaultId,
            vaultType,
            lineItemId: commentContext.id,
            hasPinnedNote: hasPinnedNote(updatedNotes),
          })
        );

        return true;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const listLineItemNotes =
  ({ mpi, lineItemId, vaultId, vaultType }) =>
  (dispatch) => {
    const filter = { noteContext: { noteContextIds: [lineItemId], type: ContextType.LINE_ITEM } };

    return listLineItemNotesApi({ mpi, filter })
      .then((notes) => {
        dispatch(
          patientActions.loadLineItemNotes({
            mpi,
            notes,
            vaultId,
            vaultType,
            lineItemId,
            hasPinnedNote: hasPinnedNote(notes),
          })
        );

        return true;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const updateNoteStatus =
  ({ noteId, action: actionState, mpi, vaultId, vaultType, lineItemId }) =>
  (dispatch, getState) => {
    const patientRecord = getState().patient[mpi];
    const existingNotes = getExisitingNotes({
      patientRecord,
      lineItemId,
      vaultId,
      vaultType,
    });

    const updatedNotes = map(existingNotes, (note) =>
      note.noteId === noteId ? { ...note, noteState: actionState } : note
    );

    dispatch(
      patientActions.loadLineItemNotes({
        mpi,
        vaultId,
        vaultType,
        lineItemId,
        notes: updatedNotes,
        hasPinnedNote: hasPinnedNote(updatedNotes),
      })
    );

    return noteActionApi({ noteId, action: actionState }).catch((error) => {
      dispatch(
        patientActions.loadLineItemNotes({
          mpi,
          lineItemId,
          notes: updatedNotes,
          hasPinnedNote: hasPinnedNote(existingNotes),
          vaultId,
          vaultType,
        })
      );

      dispatch(handleError({ error }));
    });
  };

export const updateLineItemStatus =
  ({ lineItemId, mpi, orderId, state }) =>
  (dispatch, getState) => {
    return manageProductFulfillmentApi({ lineItemId, mpi, orderId, state })
      .then((order) => {
        const { orderId: orderIdentifier, status } = order;

        const patientOrders = getState().patient[mpi]?.orders || [];
        const orderLineItems = find(patientOrders, { orderId: orderIdentifier })?.lineItems || [];

        const updatedItems = map(orderLineItems, (item) => {
          if (item.lineItemId === lineItemId) {
            return { ...item, lineItemStatus: state };
          }

          return item;
        });

        const orders = map(patientOrders, (patientOrder) => {
          if (patientOrder.orderId === orderId) {
            return {
              ...order,
              status,
              lineItems: updatedItems,
            };
          }

          return patientOrder;
        });

        dispatch(patientActions.loadOrders({ mpi, orders }));

        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const attachProductToNewOrder =
  ({ lineItemId, mpi, orderId, state, failureReason }) =>
  (dispatch, getState) => {
    const patientOrders = getState().patient[mpi]?.orders || [];

    return manageProductFulfillmentApi({
      lineItemId,
      mpi,
      orderId,
      state,
      failureReason,
    })
      .then((newOrder) => {
        const updatedOrders = reduce(
          patientOrders,
          (results, order) => {
            const lineItems = reject(order.lineItems, { lineItemId });

            return [
              ...results,
              {
                ...order,
                lineItems,
                status: every(lineItems, { lineItemStatus: 'PRODUCT_FULFILLED' })
                  ? 'ORDER_VERIFICATION'
                  : order.status,
              },
            ];
          },
          []
        );

        dispatch(patientActions.loadOrders({ mpi, orders: [...updatedOrders, newOrder] }));
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };

export const verifyOrder =
  ({ orderId, mpi }) =>
  (dispatch) => {
    return verifyOrderApi({ orderId, mpi })
      .then(({ status, orderCost, lineItems }) => {
        dispatch(patientActions.updateOrder({ mpi, orderId, status, orderCost }));
        map(lineItems, (lineItem) =>
          dispatch(patientActions.updateOrderLineItem({ ...lineItem, orderId, mpi }))
        );

        return true;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const listPatientOrders =
  ({ mpi }) =>
  (dispatch) => {
    return listPatientOrdersApi({ mpi })
      .then((orders) => dispatch(patientActions.loadOrders({ mpi, orders })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const createOrderShipment =
  ({ mpi, orderId, shipmentType }) =>
  (dispatch) => {
    return createOrderShipmentApi({ mpi, orderIds: [orderId], shipmentType })
      .then((createdOrderShipment) => {
        dispatch(patientActions.updateOrder({ mpi, orderId, status: 'ADDED_TO_SHIPMENT' }));

        return !!createdOrderShipment;
      })
      .catch((error) => {
        dispatch(listPatientOrders({ mpi }));

        return first(error.response?.errors)?.extensions?.code ===
          errorHeaders.CAPTURE_AMOUNT_EXCEEDS_AUTHORIZED_AMOUNT
          ? dispatch(handleError({ error, heading: 'Payment processing error' }))
          : dispatch(handleError({ error }));
      });
  };

export const listPatientOrderShipments =
  ({ mpi }) =>
  (dispatch, getState) => {
    const shipmentFilter = getState().patient[mpi]?.shipmentHistory?.shipmentFilter;

    return listPatientOrderShipmentsApi({ mpi, filter: shipmentFilter })
      .then((shipments) => {
        dispatch(patientActions.loadShipments({ mpi, shipments, shipmentFilter }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const updatePatientOrderShipmentsFilter =
  ({ mpi, shipmentFilter }) =>
  (dispatch) => {
    dispatch(patientActions.loadShipments({ mpi, shipments: null, shipmentFilter }));

    return dispatch(listPatientOrderShipments({ mpi }));
  };

export const getPatientUsers =
  ({ mpi }) =>
  (dispatch) => {
    return getPatientUsersApi({ mpi })
      .then((patientUsers) => dispatch(patientActions.loadPatientUsers({ mpi, patientUsers })))
      .catch((error) => dispatch(handleError({ error })));
  };

export const resetPassword =
  ({ user }) =>
  (dispatch) => {
    return resetPasswordApi({ userId: user })
      .then((response) => response)
      .catch((error) => dispatch(handleError({ error })));
  };
export const cancelOrder =
  ({ orderId, cancelReason, note, mpi }) =>
  (dispatch) => {
    const cancelNote = {
      commentContext: { id: orderId, type: 'ORDER' },
      content: { contentType: 'TEXT', message: note },
      mpi,
    };

    return cancelOrderApi({ cancelReason, orderId, cancelNote })
      .then((canceledOrder) => {
        dispatch(patientActions.removeOrder({ mpi, orderId: canceledOrder.orderId }));

        return !!canceledOrder;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const editPatientPrescription =
  ({ prescriptionId, action, mpi, autofillValue }) =>
  (dispatch) =>
    editPatientPrescriptionApi({ action, prescriptionId, autoFill: autofillValue })
      .then(({ isActive, autofill }) =>
        dispatch(
          patientActions.updatePrescriptionState({
            mpi,
            prescriptionId,
            isActive,
            autofill,
          })
        )
      )
      .catch((error) => dispatch(handleError({ error })));

export const completeEntry =
  ({ mpi, requestId, lineItemId, state }) =>
  (dispatch) =>
    completeEntryApi({ lineItemId, mpi, state })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));

export const assertQs1RxNumber =
  ({ mpi, rxNumber, lineItemId, requestId }) =>
  (dispatch) =>
    assertQs1RxNumberApi({ lineItemId, mpi, rxNumber })
      .then((requestItem) => {
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }));
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });

export const searchQS1Profile =
  ({ firstName, lastName, dob, mpi }) =>
  (dispatch) =>
    searchQs1ProfileApi({ dob, firstName, lastName }).then((qs1Profiles) => {
      dispatch(patientActions.loadPatientQs1Profile({ mpi, qs1Profiles }));
    });

export const associatePatientWithExternalProfile =
  ({ mpi, externalProfile }) =>
  (dispatch) => {
    return associatePatientWithExternalProfileApi({ externalProfile, mpi })
      .then((systemIds) => {
        dispatch(patientActions.loadSystemIds({ mpi, systemIds }));
        return true;
      })
      .catch((error) => {
        dispatch(handleError({ error }));
        return false;
      });
  };

export const updateRequestLineItemState =
  ({ mpi, requestId, lineItemId, currentState, skip, rollback }) =>
  (dispatch) =>
    updateRequestLineItemStateApi({
      mpi,
      requestId,
      lineItemId,
      currentState,
      skipState: skip,
      rollbackState: rollback,
    })
      .then((requestItem) =>
        dispatch(patientActions.updateTransferRequestStatus({ mpi, requestId, requestItem }))
      )
      .catch((error) => dispatch(handleError({ error })));

export const listPrescriptionNotes =
  ({ contextId, contextType, mpi, isPrescriptionReview }) =>
  (dispatch) => {
    const filter = { noteContext: { noteContextIds: [contextId], type: contextType } };

    return listLineItemNotesApi({ mpi, filter, isPrescriptionReview })
      .then((notes) => {
        if (!isPrescriptionReview) {
          dispatch(
            patientActions.loadPrescriptionNotes({
              mpi,
              notes,
              contextId,
            })
          );
        } else {
          dispatch(
            workQueueActions.loadReviewPrescriptionNotes({
              notes,
              contextId,
            })
          );
        }

        return true;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const addPrescriptionNotes =
  ({ mpi, content, commentContext, contextId, isPrescriptionReview }) =>
  (dispatch, getState) => {
    const prescriptions = getState().patient[mpi]?.prescriptions || [];
    const existingNotes = find(prescriptions, { prescriptionUUID: contextId })?.notes || [];

    return createLineItemNoteApi({ mpi, content, commentContext })
      .then((newNote) => {
        if (!isPrescriptionReview) {
          dispatch(
            patientActions.loadPrescriptionNotes({
              mpi,
              notes: [...existingNotes, newNote],
              contextId,
            })
          );
        } else {
          const prescriptionFromWorkQueue = getState().workQueue?.prescriptionReview;
          const currentPage = prescriptionFromWorkQueue?.currentPage;
          const prescriptionObject = find(prescriptionFromWorkQueue?.pages[currentPage], {
            pomPrescriptionId: contextId,
          });
          const existingPrescriptionReviewNotes = prescriptionObject?.notes;

          dispatch(
            workQueueActions.loadReviewPrescriptionNotes({
              notes: [...existingPrescriptionReviewNotes, newNote],
              contextId,
            })
          );
        }

        return true;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const markOrderForExternalProcessing =
  ({ mpi, orderId }) =>
  (dispatch) =>
    orderNeedsReviewApi({ mpi, orderId })
      .then((isMarkedReview) => {
        if (isMarkedReview) {
          dispatch(patientActions.removeOrder({ mpi, orderId }));
        }

        return isMarkedReview;
      })
      .catch((error) => dispatch(handleError({ error })));

export const updateOrderAddress =
  ({ mpi, orderId, addressInput }) =>
  (dispatch, getState) => {
    return updateOrderAddressApi({ addressInput, mpi, orderId })
      .then((updateOrderAddressResponse) => {
        // eslint-disable-next-line no-underscore-dangle
        if (updateOrderAddressResponse?.__typename === 'Addresses') {
          return updateOrderAddressResponse.addresses;
        }

        const existingAddresses = getState().patient[mpi]?.addresses || [];
        const isNewAddressDefaulted = addressInput.isDefault;
        const updatedExistingAddress = buildPatientAddress({
          existingAddresses,
          isNewAddressDefaulted,
        });

        dispatch(
          patientActions.loadAddresses({
            mpi,
            addresses: [updateOrderAddressResponse, ...updatedExistingAddress],
          })
        );

        return dispatch(listPatientOrders({ mpi })).then((orders) => !!orders);
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const markPrescriptionReview =
  ({ patientId, prescriptionUUID, lineItemId, orderId }) =>
  (dispatch) => {
    return prescriptionNeedsReviewAPI({ patientId, prescriptionUUID })
      .then((response) => {
        if (response) {
          dispatch(
            patientActions.updateOrderLineItemTaggedReview({
              patientId,
              orderId,
              lineItemId,
              taggedForReview: true,
            })
          );
        }

        return response;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const reverifyOrder =
  ({ orderId, mpi }) =>
  (dispatch) => {
    return reverifyOrderApi({ orderId, mpi })
      .then(({ status }) => {
        dispatch(patientActions.updateOrder({ mpi, orderId, status }));

        return !!status;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const editOrderShipment =
  ({ input, mpi }) =>
  (dispatch) => {
    return editOrderShipmentApi(input)
      .then((updatedShipment) => {
        dispatch(listPatientOrderShipments({ mpi }));

        return updatedShipment;
      })
      .catch((error) => dispatch(handleError({ error })));
  };
