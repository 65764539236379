import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { find, map, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { validateValueWithRegex } from 'common/forms/formValidations';
import { updatePatientAddress } from 'store/thunks/patientThunks';
import { patientActions } from 'store/slices/patientSlice';

import useSmsResetWarningDialog from './useSmsResetWarningDialog';

const EditPatientAddressDialog = ({ mpi, patientAddressId, open, onClose }) => {
  const { InfoDialog, showWarning } = useSmsResetWarningDialog();
  const dispatch = useDispatch();
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const patientAddress = find(patientInfo?.addresses, { patientAddressId });
  const notificationPreference = patientInfo?.notificationPreferences;
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const dispatchEditPatientAddress = ({ address }) => {
    setLoading(true);
    dispatch(
      updatePatientAddress({
        mpi,
        address,
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
      setLoading(false);
    });
  };

  const resetPhonePreference = () => {
    dispatch(
      patientActions.updatePatientNotificationPreference({
        phone: false,
        email: notificationPreference?.email,
        mpi,
      })
    );
  };

  const handleEditPatientAddress = (formData) => {
    const address = {
      ...omit(formData, 'address', 'createdTimestamp'),
      addressLine2: formData.addressLine2 || '',
    };

    const isDisallowedState = !find(usStateAbbreviation, {
      abbreviation: address.state,
    })?.isSMSAllowed;

    showWarning({
      warn: address.isDefault && isDisallowedState && notificationPreference?.phone,
      callback: () => dispatchEditPatientAddress({ address }),
      warnCallback: () => {
        resetPhonePreference();
        dispatchEditPatientAddress({ address });
      },
    });
  };

  return (
    <>
      <InfoDialog />

      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-describedby='PatientView-EditPatientAddress-header'
        fullWidth
      >
        <DialogTitle id='PatientView-EditAddress-header'>Edit Address</DialogTitle>

        <Form
          initialValues={{ ...patientAddress, ...patientAddress?.address }}
          onSubmit={handleEditPatientAddress}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-EditPatientAddress-nameOnAddress-input'
                  label='Name On Address'
                  name='nameOnAddress'
                  required
                  autoFocus
                />
                <TextField
                  id='PatientView-EditPatientAddress-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />
                <TextField
                  id='PatientView-EditPatientAddress-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />
                <TextField
                  id='PatientView-EditPatientAddress-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-EditPatientAddress-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>
                <TextField
                  id='PatientView-EditPatientAddress-zip-input'
                  label='Zip'
                  name='zip'
                  required
                  validations={[validateValueWithRegex('Must be 5 numbers', /^\d{5}$/)]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

EditPatientAddressDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientAddressId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPatientAddressDialog;
