/* eslint-disable import/prefer-default-export */
import { GraphQLClient } from 'graphql-request';
import { getCookie, setCookie } from 'api/utils';
import { configuredStore } from 'store';

const ENV = process.env.NODE_ENV;
const isProd = ENV === 'production';

/* istanbul ignore next */
const endpoint = isProd
  ? `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`
  : `${window.location.origin}/graphql`;

const requestMiddleware = (request) => {
  const jwt = getCookie('pomAccessToken');
  const sfgp = getCookie('tokenSfgp');
  const { currentTenantId } = configuredStore.store.getState().auth;

  return {
    ...request,
    headers: {
      ...request.headers,
      'x-token-sfgp': sfgp,
      Authorization: jwt,
      ...(currentTenantId && { 'x-fulfilling-pharmacy-id': currentTenantId }),
    },
  };
};

const responseMiddleware = (response) => {
  const { headers } = response;

  const newToken = headers?.get('x-new-token');
  /* istanbul ignore if */
  if (newToken) {
    setCookie({ name: 'pomAccessToken', value: newToken });
  }

  const sfgp = headers?.get('x-token-sfgp');
  /* istanbul ignore if */
  if (sfgp) {
    setCookie({ name: 'tokenSfgp', value: sfgp });
  }
};

export default new GraphQLClient(endpoint, { requestMiddleware, responseMiddleware });
