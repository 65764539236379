/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getPartnersGql = gql`
  query getPartners {
    getPartners {
      partnerName
      partnerUuid
    }
  }
`;
