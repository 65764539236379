import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { filter, find, includes, map, omit, reduce } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validateEmail,
  validatePhoneNumber,
  validateValueWithRegex,
} from 'common/forms/formValidations';
import CheckboxField from 'common/forms/CheckboxField';
import guarantorRelationship from 'common/constants/guarantorRelationship';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { updateGuarantor } from 'store/thunks/patientThunks';

const EditGuarantorDialog = ({ mpi, guarantorId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) => {
    return find(patient[mpi]?.guarantors, { guarantorId });
  });
  const patientName = useSelector(
    ({ patient }) => ({
      firstName: patient[mpi]?.firstName,
      middleName: patient[mpi]?.middleName,
      lastName: patient[mpi]?.lastName,
    }),
    shallowEqual
  );

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleUpdateGuarantor = (formData) => {
    const phoneNumbers = reduce(
      formData,
      (results, value, key) => {
        switch (key) {
          case 'mobilePhone':
            return [...results, { phoneNumber: value, tags: ['MOBILE'] }];
          case 'workPhone':
            return [...results, { phoneNumber: value, tags: ['WORK'] }];
          case 'homePhone':
            return [...results, { phoneNumber: value, tags: ['HOME'] }];
          default:
            return results;
        }
      },
      []
    );

    return dispatch(
      updateGuarantor({
        guarantor: {
          mpi,
          ...omit(formData, ['mobilePhone', 'workPhone', 'homePhone']),
          ...(formData.relationship === 'SELF' && patientName),
          phoneNumbers: filter(phoneNumbers, 'phoneNumber'),
          firstName: formData.firstName || '',
          lastName: formData.lastName || '',
          middleName: formData.middleName || '',
          addressLine1: formData.addressLine1 || '',
          addressLine2: formData.addressLine2 || '',
          city: formData.city || '',
          state: formData.state || '',
          zip: formData.zip || '',
          textsEnabled: !!formData.textsEnabled,
          isEmergencyContact: !!formData.isEmergencyContact,
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditGuarantorDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditGuarantorDialog-header'>Edit Guarantor</DialogTitle>

      <Form
        initialValues={{
          ...omit(initialValues, ['patientRelationship']),
          relationship: initialValues?.patientRelationship.relationship,
          mobilePhone: find(initialValues?.phoneNumbers, ({ tags }) => includes(tags, 'MOBILE'))
            ?.phoneNumber,
          workPhone: find(initialValues?.phoneNumbers, ({ tags }) => includes(tags, 'WORK'))
            ?.phoneNumber,
          homePhone: find(initialValues?.phoneNumbers, ({ tags }) => includes(tags, 'HOME'))
            ?.phoneNumber,
        }}
        onSubmit={handleUpdateGuarantor}
        render={({ handleSubmit, invalid, submitting, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <SelectField
                name='relationship'
                id='PatientView-EditGuarantorDialog-relationship-dropdown'
                label='Relationship'
                required
              >
                {map(guarantorRelationship, (value, key) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>

              {values.relationship !== 'SELF' && (
                <>
                  <TextField
                    id='PatientView-EditGuarantorDialog-firstName-input'
                    label='First Name'
                    name='firstName'
                    required
                  />

                  <TextField
                    id='PatientView-EditGuarantorDialog-middleName-input'
                    label='Middle Name'
                    name='middleName'
                  />

                  <TextField
                    id='PatientView-EditGuarantorDialog-lastName-input'
                    label='Last Name'
                    name='lastName'
                    required
                  />
                </>
              )}

              <TextField
                id='PatientView-EditGuarantorDialog-email-input'
                label='Email'
                name='email'
                required
                validations={[validateEmail()]}
              />

              <TextField
                id='PatientView-EditGuarantorDialog-mobilePhoneNumber-input'
                label='Mobile Phone Number'
                name='mobilePhone'
                required
                validations={[validatePhoneNumber()]}
              />

              <TextField
                id='PatientView-EditGuarantorDialog-workPhoneNumber-input'
                label='Work Phone Number'
                name='workPhone'
                validations={[validatePhoneNumber()]}
              />

              <TextField
                id='PatientView-EditGuarantorDialog-homePhoneNumber-input'
                label='Home Phone Number'
                name='homePhone'
                validations={[validatePhoneNumber()]}
              />

              <TextField
                id='PatientView-EditGuarantorDialog-addressLine1-input'
                label='Address Line 1'
                name='addressLine1'
              />

              <TextField
                id='PatientView-EditGuarantorDialog-addressLine2-input'
                label='Address Line 2'
                name='addressLine2'
              />

              <TextField id='PatientView-EditGuarantorDialog-city-input' label='City' name='city' />

              <SelectField
                id='PatientView-EditGuarantorDialog-state-dropdown'
                label='State'
                name='state'
              >
                {map(usStateAbbreviation, ({ name, abbreviation }) => (
                  <MenuItem value={abbreviation} key={abbreviation}>
                    {name}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField
                id='PatientView-EditGuarantorDialog-zip-input'
                label='Zip'
                name='zip'
                validations={[validateValueWithRegex('Must be 5 numbers', /^\d{5}$/)]}
              />

              <CheckboxField
                label='Text Notification Preference'
                name='textsEnabled'
                id='PatientView-EditGuarantorDialog-textNotificationPreference-checkbox'
              />

              <CheckboxField
                label='Emergency Contact'
                name='isEmergencyContact'
                id='PatientView-EditGuarantorDialog-isEmergencyContact-checkbox'
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditGuarantorDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  guarantorId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditGuarantorDialog;
