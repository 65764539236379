/* eslint-disable import/prefer-default-export */
import { getPartnersApi } from 'api/partner';
import { partnerActions } from 'store/slices/partnerSlice';

import { handleError } from './errorHandlerThunks';

export const getPartners = () => (dispatch, getState) => {
  const { currentTenantId } = getState().auth;

  return getPartnersApi()
    .then((partners) => {
      dispatch(partnerActions.loadPartners({ partners, currentTenantId }));
    })
    .catch((error) => dispatch(handleError({ error })));
};
