import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, isNil, values } from 'lodash';

import { filterBulkLabels } from 'store/thunks/workQueueThunks';
import lineItemEnum from 'enums/lineItem.enum';

const BulkLabelsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.bulkLabels?.filter);

  const removeFilter = (filterName) => () => {
    dispatch(filterBulkLabels({ filter: { ...filter, [filterName]: undefined } }));
  };

  const removeAllFilter = () => dispatch(filterBulkLabels({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.medicationName && (
        <Chip
          label={`Medication Name = ${filter.medicationName}`}
          variant='contained'
          onDelete={removeFilter('medicationName')}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('needsByDate')}
        />
      )}

      {filter.lastPrintedDate && (
        <Chip
          label={`Last Printed Date = ${format(new Date(filter.lastPrintedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('lastPrintedDate')}
        />
      )}

      {!isNil(filter.isPrinted) && (
        <Chip
          label={`Printed = ${filter.isPrinted ? 'Yes' : 'No'}`}
          variant='contained'
          onDelete={removeFilter('isPrinted')}
        />
      )}

      {!isNil(filter.taggedForReview) && (
        <Chip
          label={`Tagged For Review = ${filter.taggedForReview ? 'Yes' : 'No'}`}
          variant='contained'
          onDelete={removeFilter('taggedForReview')}
        />
      )}

      {!isNil(filter.lineItemStatus) && (
        <Chip
          label={`Product Status = ${
            filter.lineItemStatus === lineItemEnum.PRODUCT_FULFILLMENT ? 'Needs Filled' : 'Filled'
          }`}
          variant='contained'
          onDelete={removeFilter('lineItemStatus')}
        />
      )}

      {(!isEmpty(compact(values(filter))) || !isNil(filter.isPrinted)) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default BulkLabelsFilterStatusBar;
