/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Field } from 'react-final-form';
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

import { composeValidations, validateRequired } from './formValidations';

const TextField = ({
  name,
  id,
  label,
  validations,
  autoFocus,
  type,
  maxLength,
  row,
  maxRows,
  minRows,
  multiline,
  required,
  submitOnEnter,
  startAdornment,
  endAdornment,
  format,
  className,
  autoComplete,
  readOnly,
  helperText,
  formatOnBlur,
  value,
  params,
  disabledLabel,
}) => {
  const validate = composeValidations([
    ...validations,
    ...(required ? [validateRequired('Required')] : []),
  ]);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((prevState) => !prevState);

  const displayedType = type === 'password' && showPassword ? 'text' : type;

  return (
    <Field
      name={name}
      id={id}
      type={displayedType}
      validate={validate}
      format={format}
      formatOnBlur={formatOnBlur}
      render={({ input, meta }) => {
        // showError needs to be a boolean, material type check this value
        const showError = !!meta.error && !!meta.touched;
        const displayedHelperText = (!!meta.touched && meta.error) || helperText;

        const handleBlurFormat = (inputVal) => {
          const formattedVal = typeof inputVal === 'string' ? inputVal.trim() : inputVal;

          input.onChange(formattedVal);
        };

        return (
          <FormControl error={showError} variant='standard' fullWidth>
            <Box sx={{ opacity: disabledLabel ? 0 : 1 }}>
              <InputLabel
                shrink
                htmlFor={id}
                required={required}
                sx={{ fontSize: '1.2em' }}
                {...params?.InputLabelProps}
              >
                {label}
              </InputLabel>
            </Box>

            <InputBase
              sx={{
                'label + &': {
                  marginTop: '1.3em',
                },
                marginTop: '1.3em',
                boxSizing: 'border-box',
                borderRadius: 1,
                marginBottom: 0.25,
                padding: '4px 8px',
                '&:focus, &:focus&:invalid, &.Mui-focused': {
                  borderWidth: 2,
                  padding: '3px 7px',
                },
              }}
              id={id}
              className={className}
              label={label}
              autoFocus={autoFocus}
              minRows={minRows}
              row={row}
              multiline={multiline}
              required={required}
              readOnly={readOnly}
              {...(!minRows && { maxRows })}
              startAdornment={
                startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>
              }
              endAdornment={
                (endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>) ||
                (type === 'password' && (
                  <InputAdornment position='end'>
                    <IconButton
                      id={`toggle-${id}-visibility`}
                      aria-label={`${showPassword ? 'Hide' : 'Show'} Password`}
                      onClick={toggleShowPassword}
                      size='small'
                    >
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ))
              }
              inputProps={{
                maxLength,
                autoComplete,
                'aria-required': required,
                onKeyPress: (event) => {
                  const disableSubmitOnEnter =
                    event.key === 'Enter' && !submitOnEnter && !multiline;
                  if (disableSubmitOnEnter) event.preventDefault();
                },
                onBlur: (e) => input.onBlur(handleBlurFormat(e.target.value)),
                ...params?.inputProps,
              }}
              onFocus={(
                e // https://github.com/mui-org/material-ui/issues/12779
              ) =>
                e.currentTarget.setSelectionRange(
                  e.currentTarget.value.length,
                  e.currentTarget.value.length
                )
              }
              {...params?.InputProps}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input} // intentional spreading, injecting final-form
              value={input.value || value || ''} // https://github.com/final-form/react-final-form/issues/529
            />

            <FormHelperText>{displayedHelperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  row: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  format: PropTypes.func,
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  readOnly: PropTypes.bool,
  helperText: PropTypes.string,
  formatOnBlur: PropTypes.bool,
  value: PropTypes.string,
  disabledLabel: PropTypes.bool,
  params: PropTypes.shape({
    inputProps: PropTypes.shape({}),
    InputProps: PropTypes.shape({}),
    InputLabelProps: PropTypes.shape({}),
  }),
};

TextField.defaultProps = {
  validations: [],
  autoFocus: false,
  label: '',
  type: 'text',
  maxLength: 50,
  required: false,
  row: 1,
  maxRows: 1,
  minRows: 1,
  multiline: false,
  submitOnEnter: false,
  startAdornment: undefined,
  endAdornment: undefined,
  format: undefined,
  className: undefined,
  autoComplete: 'off',
  readOnly: false,
  helperText: '',
  formatOnBlur: false,
  value: undefined,
  params: undefined,
  disabledLabel: false,
};

export default TextField;
