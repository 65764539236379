import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { find, isEmpty, isObject, map, omit } from 'lodash';
import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';

import TimeLineView from 'common/modules/TimelineView';
import useTabContext, { TabProvider } from 'common/hooks/useTabContext';
import NothingFound from 'common/components/NothingFound';

const TabPanel = ({ children, value }) => {
  const { tab } = useTabContext();
  const hidden = value !== tab;
  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`work-queue-tabpanel-${value}`}
      aria-labelledby={`work-queue-tab-${value}`}
    >
      <Box sx={{ px: 1 }}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const a11yProps = (index) => ({
  id: `work-queue-tab-${index}`,
  'aria-controls': `work-queue-tabpanel-${index}`,
});

const EScriptTimelineView = ({ referenceId, timeLineType }) => {
  const { pages, currentPage } = useSelector(({ orderStatus: { eScriptOrders } }) => eScriptOrders);
  const orderData = find(pages[currentPage], (request) => request?.referenceId === referenceId);
  const timeLineData = orderData?.requestDetails[timeLineType];

  const timeLinePayload = map(
    timeLineData,
    ({
      id,
      actionItemType,
      timestamp,
      interactionData,
      isProcessSuccess,
      orderShipmentId,
      user,
    }) => {
      const payload = omit(interactionData, ['originalEventPayload']);
      const requestDetails = {
        firstName: orderData?.patient?.firstName,
        lastName: orderData?.patient?.lastName,
        actionItemType,
        orderId: orderData?.pomOrderId,
        requestId: interactionData?.requestId,
        orderShipmentId,
        rxNumber: interactionData?.rxNumber,
        referenceId: orderData?.referenceId,
        user,
      };

      return {
        id,
        requestDetails,
        actionItemType,
        isProcessSuccess,
        eventPayload: { ...(isObject(payload) ? payload : { payload }) },
        timestamp: moment.utc(timestamp).local().format('MM/DD/YYYY hh:mm:ss A'),
      };
    }
  );

  return (
    <Grid container sx={{ mt: 3 }}>
      {!isEmpty(timeLinePayload) ? (
        <TimeLineView timeLineData={timeLinePayload} />
      ) : (
        <NothingFound />
      )}
    </Grid>
  );
};

EScriptTimelineView.propTypes = {
  referenceId: PropTypes.string.isRequired,
  timeLineType: PropTypes.string.isRequired,
};

const tabsDetails = [
  {
    tabName: 'Events',
    Component: EScriptTimelineView,
    tabValue: 'events',
  },
  {
    tabName: 'Errors',
    Component: EScriptTimelineView,
    tabValue: 'errors',
  },
];

const TabList = () => {
  const { tab, setTab } = useTabContext();

  return (
    <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={setTab}
        aria-label='Patient Details Tabs'
        scrollButtons='auto'
        sx={{ maxWidth: '85vw' }}
        variant='scrollable'
      >
        {map(tabsDetails, ({ tabName, tabValue }, key) => (
          <Tab label={tabName} {...a11yProps(key)} key={key} value={tabValue} />
        ))}
      </Tabs>
    </Box>
  );
};

const EScriptOrdersProgressTab = ({ initialTab, referenceId }) => {
  return (
    <TabProvider initialValue={initialTab}>
      <Grid container component={Paper} sx={{ px: 2 }} direction='column'>
        <TabList />
        {map(tabsDetails, ({ Component, tabValue }) => (
          <TabPanel value={tabValue} key={tabValue}>
            <Component referenceId={referenceId} timeLineType={tabValue} />
          </TabPanel>
        ))}
      </Grid>
    </TabProvider>
  );
};

EScriptOrdersProgressTab.propTypes = {
  initialTab: PropTypes.string.isRequired,
  referenceId: PropTypes.string.isRequired,
};

export default EScriptOrdersProgressTab;
