import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';
import { getPartners } from 'store/thunks/partnerThunks';
import { workQueueActions } from 'store/slices/workQueueSlice';
import roles from 'common/constants/roles';
import useRoles from 'common/hooks/useRoles';

import WorkQueueTab from './components/WorkQueueTab';

const WorkQueue = () => {
  const dispatch = useDispatch();
  const { hasAuthorizedRoles } = useRoles();
  const preset = useSelector(({ workQueue }) => workQueue.preset);
  const [initialTab, setInitialTab] = useState(preset);

  useEffect(() => {
    document.title = 'Work Queue - SmartOS';
  }, []);

  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  useEffect(() => {
    if (!preset) {
      // Don't need to add another if statement just a default preset screen based on role. Like when you login to Facebook it defaults to the feed not your profile page.
      const getInitialPreset = () => {
        if (hasAuthorizedRoles([roles.admin])) return WorkQueues.PATIENT_QUALIFICATION;
        if (hasAuthorizedRoles([roles.billingUser, roles.ccc]))
          return WorkQueues.PATIENT_QUALIFICATION;
        if (hasAuthorizedRoles([roles.pharmTech, roles.pharmacist]))
          return WorkQueues.IN_CLAIM_ELIGIBILITY;
        if (hasAuthorizedRoles([roles.pharmacyProdTech])) return WorkQueues.BULK_LABELS;
        if (hasAuthorizedRoles([roles.shippingUser])) return WorkQueues.READY_TO_SHIP;
        throw Error('No available queue for this role');
      };
      const initialPreset = getInitialPreset();
      setInitialTab(initialPreset);
      dispatch(workQueueActions.loadSelectedWorkQueues(initialPreset));
    }
  }, [dispatch, hasAuthorizedRoles, preset]);

  return (
    <Grid container sx={{ px: 3, pb: 2 }} direction='column'>
      <Grid item container alignItems='center' spacing={2}>
        <Grid item>
          <Typography component='h1' variant='h5'>
            Work Queues
          </Typography>
        </Grid>
      </Grid>

      <Grid item sx={{ flexgrow: 1, mt: 2 }}>
        {initialTab && <WorkQueueTab initialTab={initialTab} />}
      </Grid>
    </Grid>
  );
};

export default WorkQueue;
