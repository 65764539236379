import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validatePhoneNumber, validateValueWithRegex } from 'common/forms/formValidations';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { addPatientPharmacy } from 'store/thunks/patientThunks';

const NewPharmacyButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const moreThanTenPharmacies = useSelector(
    ({ patient }) => !!patient[mpi]?.pharmacies?.length > 9
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddPharmacy = (formData) => {
    return dispatch(addPatientPharmacy({ pharmacy: { mpi, ...formData } })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Button
        variant='contained'
        disabled={!patientName || moreThanTenPharmacies}
        onClick={handleOpenModal}
      >
        New
      </Button>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewPharmacyButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewPharmacyButton-header'>Add Pharmacy</DialogTitle>
        <Form
          onSubmit={handleAddPharmacy}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-NewPharmacyButton-pharmacyName-input'
                  label='Pharmacy Name'
                  name='name'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientView-NewPharmacyButton-pharmacyPhoneNumber-input'
                  label='Phone Number'
                  name='phoneNumber'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewPharmacyButton-fax-input'
                  label='Fax'
                  name='fax'
                  validations={[validatePhoneNumber('Invalid Fax Number')]}
                />

                <TextField
                  id='PatientView-NewPharmacyButton-npi-input'
                  label='NPI'
                  name='npi'
                  validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
                />

                <TextField
                  id='PatientView-NewPharmacyButton-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientView-NewPharmacyButton-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientView-NewPharmacyButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientView-NewPharmacyButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewPharmacyButton-zip-input'
                  label='Zip'
                  name='zip'
                  required
                  validations={[validateValueWithRegex('Must be 5 numbers', /^\d{5}$/)]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};
NewPharmacyButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewPharmacyButton;
